import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;
  background: black;
  ${({ active }) =>
    active
      ? css`
          filter: blur(0);
          opacity: 1;
        `
      : css`
          filter: blur(5px);
          opacity: 0;
        `}
  transition: all 0.6s ease;

  > div {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  img {
    position: relative;
    top: -50%;
    width: 100%;
  }

  .react-parallax-background-children {
    width: 140%;
  }

  ${({ theme }) => theme.media.l} {
    .react-parallax-background-children {
      width: 105%;
    }
  }
`
