import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'
import ReactMarkdown from 'react-markdown'

import { Wrapper, Content, CustomHeader } from './styles.js'

import ImgSrc from '@images/template/template6.png'

const TextSectionImage = ({ children, data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <HorWrap>
          <Content active={sectionInView}>
            <div>
              <CustomHeader active={sectionInView}>
                <span>
                  {data.header.split(' ').map(word => (
                    <span>{word}</span>
                  ))}
                </span>
              </CustomHeader>
              {data.text}
            </div>
            <div>
              <img src={data.image.url} />
            </div>
          </Content>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

TextSectionImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

TextSectionImage.defaultProps = {
  children: null,
}

export default TextSectionImage
