import styled, { css } from 'styled-components'

export const Wrapper = styled.section``

export const Content = styled.article`
  > div {
    transition: opacity 0.8s 0.5s ease;
    opacity: ${({ active }) => (active ? '1' : '0')};

    &:nth-child(2) {
      transition-delay: 0.8s;
    }
  }

  p {
    margin: 20px 0 60px;
    max-width: 600px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66;
    color: black;
  }

  ${({ theme }) => theme.media.l} {
    display: grid;
    ${({ centered }) =>
      centered
        ? css`
            grid-template-columns: 1fr 2fr;
            grid-column-gap: 10%;
          `
        : css`
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 25%;
          `}
    grid-row-gap: 0;
  }
`

export const CustomHeader = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  font-size: 28px;
  font-weight: 900;

  > span {
    overflow: hidden;
    position: relative;
    display: inline-block;

    > span > span {
      position: relative;
      display: inline-block;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: transform 0.8s 0s ease;
    }

    span + span {
      padding-left: 5px;
    }

    > span:nth-child(2) > span {
      transition-delay: 0.2s;
    }

    > span:nth-child(3) > span {
      transition-delay: 0.4s;
    }

    > span:nth-child(4) > span {
      transition-delay: 0.6s;
    }

    > span:nth-child(5) > span {
      transition-delay: 0.8s;
    }

    > span:nth-child(6) > span {
      transition-delay: 1s;
    }

    > span:nth-child(7) > span {
      transition-delay: 1.2s;
    }

    > span:nth-child(8) > span {
      transition-delay: 1.4s;
    }

    > span:nth-child(9) > span {
      transition-delay: 1.6s;
    }

    > span:nth-child(10) > span {
      transition-delay: 1.8s;
    }
  }

  ${({ theme }) => theme.media.m} {
    font-size: 42px;

    > span span + span {
      padding-left: 9px;
    }
  }

  ${({ theme }) => theme.media.l} {
    font-size: 52px;

    > span span + span {
      padding-left: 12px;
    }
  }
`
