import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  font-size: 28px;
  font-weight: 900;

  > span {
    overflow: hidden;
    position: relative;
    display: inline-block;

    > span > span {
      position: relative;
      display: inline-block;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: transform 0.8s 0s ease;
    }

    span + span {
      padding-left: 5px;
    }

    > span:nth-child(2) > span {
      transition-delay: 0.2s;
    }

    > span:nth-child(3) > span {
      transition-delay: 0.4s;
    }

    > span:nth-child(4) > span {
      transition-delay: 0.6s;
    }

    > span:nth-child(5) > span {
      transition-delay: 0.8s;
    }

    > span:nth-child(6) > span {
      transition-delay: 1s;
    }

    > span:nth-child(7) > span {
      transition-delay: 1.2s;
    }

    > span:nth-child(8) > span {
      transition-delay: 1.4s;
    }

    > span:nth-child(9) > span {
      transition-delay: 1.6s;
    }

    > span:nth-child(10) > span {
      transition-delay: 1.8s;
    }
  }

  ${({ theme }) => theme.media.m} {
    padding: 70px 30px;
    font-size: 42px;

    > span span + span {
      padding-left: 9px;
    }
  }

  ${({ theme }) => theme.media.l} {
    padding: 80px 30px;
    font-size: 52px;

    > span span + span {
      padding-left: 12px;
    }
  }
`
