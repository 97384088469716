import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import { Wrapper } from './styles.js'

const Quote = ({ text }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <span>
          {text.split(' ').map((item, index) => (
            <span key={item + index}>
              <span>{item}</span>
            </span>
          ))}
        </span>
      </Wrapper>
    </InView>
  )
}

Quote.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Quote.defaultProps = {
  children: null,
}

export default Quote
