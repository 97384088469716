import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  overflow: hidden;
  width: 100%;
  background: black;
  transition: all 0.6s ease;

  > img {
    width: 100%;
    height: auto;
  }

  ${({ active }) =>
    active
      ? css`
          filter: blur(0);
          opacity: 1;
        `
      : css`
          filter: blur(5px);
          opacity: 0;
        `}
`
