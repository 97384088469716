import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { Parallax, Background } from 'react-parallax'

import { Wrapper } from './styles.js'

const BigImage = ({ image }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <Parallax strength={200}>
          <Background>{image && <img src={image} alt="" />}</Background>
        </Parallax>
      </Wrapper>
    </InView>
  )
}

BigImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

BigImage.defaultProps = {
  children: null,
}

export default BigImage
