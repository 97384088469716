import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import { Wrapper, Content, CustomHeader } from './styles.js'
import ReactMarkdown from 'react-markdown'

const TextSection = ({ data, children }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <HorWrap>
          {data.centered && (
            <Content active={sectionInView} centered>
              <div>
                {data.centeredHeader && (
                  <CustomHeader active={sectionInView}>
                    <span>
                      {data.centeredHeader.split(' ').map(word => (
                        <span>{word}</span>
                      ))}
                    </span>
                  </CustomHeader>
                )}
              </div>
              <div>
                {data.centered && <ReactMarkdown children={data.centered} />}
              </div>
            </Content>
          )}
          <Content active={sectionInView}>
            <div>
              {data.leftHeader && (
                <CustomHeader active={sectionInView}>
                  <span>
                    {data.leftHeader.split(' ').map(word => (
                      <span>{word}</span>
                    ))}
                  </span>
                </CustomHeader>
              )}
              {data.left && <ReactMarkdown children={data.left} />}
            </div>
            <div>
              {data.rightHeader && (
                <CustomHeader active={sectionInView}>
                  <span>
                    {data.rightHeader.split(' ').map(word => (
                      <span>{word}</span>
                    ))}
                  </span>
                </CustomHeader>
              )}
              {data.right && <ReactMarkdown children={data.right} />}
            </div>
          </Content>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

TextSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

TextSection.defaultProps = {
  children: null,
}

export default TextSection
