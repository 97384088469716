import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Main from '@templates/Main'

import BigImage from '@blocks/BigImage'
import ColorPallete from '@blocks/ColorPallete'
import Credits from '@blocks/Credits'
import FullBleedImage from '@blocks/FullBleedImage'
import Hero from '@blocks/Hero'
import Next from '@blocks/Next'
import Quote from '@blocks/Quote'
import SliderSection from '@blocks/SliderSection'
import Spacer from '@blocks/Spacer'
import TextSection from '@blocks/TextSection'
import TextSectionImage from '@blocks/TextSectionImage'
import Typography from '@blocks/Typography'

const ProjectPage = ({ data: { project }, transitionStatus }) => {
  const blocks = project.websiteContent
  return (
    <Main
      seo={project.seoMetaTags}
      transitionStatus={transitionStatus}
      enterColor={project.color.hex}
    >
      {blocks.map(block => {
        let blockComponent

        block.id.startsWith('DatoCmsHero') &&
          (blockComponent = <Hero key={block.id} data={block} />)

        block.id.startsWith('DatoCmsColorPalette') &&
          (blockComponent = <ColorPallete key={block.id} data={block.colors} />)

        block.id.startsWith('DatoCmsQuote') &&
          (blockComponent = <Quote key={block.id} text={block.quote} />)

        block.id.startsWith('DatoCmsHalfTextImage') &&
          (blockComponent = <TextSectionImage key={block.id} data={block} />)

        block.id.startsWith('DatoCmsSlider') &&
          (blockComponent = (
            <SliderSection key={block.id} data={block.images} />
          ))

        block.id.startsWith('DatoCmsCreditsDescription') &&
          (blockComponent = <Credits key={block.id} data={block} />)

        block.id.startsWith('DatoCmsTextSection') &&
          (blockComponent = <TextSection key={block.id} data={block} />)

        block.id.startsWith('DatoCmsSpacer') &&
          (blockComponent = (
            <Spacer
              key={block.id}
              size={block.size}
              showSize={
                project.id === 'DatoCmsProject-145114826' ? true : false
              }
            />
          ))

        block.id.startsWith('DatoCmsFullBleedImage') &&
          (blockComponent = <FullBleedImage key={block.id} data={block} />)

        return blockComponent
      })}

      <Next data={project.nextProject} />
    </Main>
  )
}

export const query = graphql`
  query ProjectBySlug($id: String) {
    project: datoCmsProject(id: { eq: $id }) {
      id
      name
      slug
      seoMetaTags {
        tags
      }
      color {
        hex
      }
      websiteContent {
        ... on DatoCmsHero {
          id
          nameHeaderFallback
          textColor {
            hex
          }
          bgImageMobile {
            url
          }
          bgImage {
            url
          }
          bgColor {
            hex
          }
          logo {
            url
          }
          awards {
            logo {
              url
            }
          }
        }
        ... on DatoCmsColorPalette {
          id
          colors {
            name
            rgb
            cmyk
            color {
              hex
            }
            textColor {
              hex
            }
          }
        }
        ... on DatoCmsQuote {
          id
          quote
        }
        ... on DatoCmsHalfTextImage {
          id
          header
          text
          image {
            url
          }
        }
        ... on DatoCmsSlider {
          id
          images {
            fixed {
              src
            }
          }
        }
        ... on DatoCmsCreditsDescription {
          id
          header
          description
          credits
        }
        ... on DatoCmsTextSection {
          id
          centeredHeader
          centered
          leftHeader
          left
          rightHeader
          right
        }
        ... on DatoCmsSpacer {
          id
          size
        }
        ... on DatoCmsFullBleedImage {
          id
          centeredHeader
          centered
          leftHeader
          left
          rightHeader
          right
          backgroundMobile {
            url
            sizes {
              aspectRatio
            }
          }
          background {
            url
            sizes {
              aspectRatio
            }
          }
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
        }
      }
      nextProject {
        ... on DatoCmsProject {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectSpotify {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectFroodie {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectJayne {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectOracle {
          id
          name
          slug
          color {
            hex
          }
        }
      }
    }
  }
`

export default ProjectPage
